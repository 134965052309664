.App {
    min-height: 100vh;
}

.App.theme-felleskatalogen {
    background: #f5f7fb;
    min-height: 100vh;
}

@import-normalize;

.header-logo {
    max-width: 55%;
}

.footer img {
    flex: 1;
    width: 25%;
}

.footer img.customer {
    width: 20%;
    flex: none;
}

.binned-width {
    max-width: calc(800px - 2em); /* accounting for padding */
    margin: 0 auto;
}

@font-face {
    font-family: Montserrat-Bold;
    src: url('../src/fonts/Montserrat-Bold.ttf') format('truetype');
}

@font-face {
    font-family: Montserrat-SemiBold;
    src: url('../src/fonts/Montserrat-SemiBold.ttf');
}

@font-face {
    font-family: Montserrat-Medium;
    src: url('../src/fonts/Montserrat-Medium.ttf');
}

@font-face {
    font-family: Montserrat-Regular;
    src: url('../src/fonts/Montserrat-Regular.ttf');
}

@font-face {
    font-family: Montserrat-Thin;
    src: url('../src/fonts/Montserrat-Thin.ttf');
}

body {
    margin: 0;
    font-family: Montserrat-Regular, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 160%;
    background-color: #eee;
}

.container,
.footer {
    display: flex;
    flex-direction: column;
    max-width: 800px;
    width: min(800px, 100vw);
    margin: 0 auto;
}

h1 {
    font-size: 120%;
}

p {
    margin-bottom: 20px;
}

li {
    margin-bottom: 10px;
}

.padded {
    margin: 1em;
}

.side-margin {
    margin: 0 1em;
}

header {
    color: #000;
    padding: 0.5em 1em;
    line-height: 100%;
    background-color: #fff;
}

.footer {
    margin-top: auto;

    position: sticky;
    bottom: 0;
    box-shadow: 0 0 0.5em #0003;
    background: #fffe;
    backdrop-filter: blur(4px);
}

.overflow-scroll {
    max-height: 40vh;
    overflow-y: scroll;
}

.opacity-05 {
    opacity: 0.5;
}

.opacity-02 {
    opacity: 0.2;
}

table,
th,
td {
    border: 1px solid #999;
    border-collapse: collapse;
}

/* On smaller screens, make sure tables doesn't overflow and ruin the rest of the article */
.table-container {
    overflow-x: auto;
}

th {
    text-align: left;
    background-color: #f2f2f2;
}

th,
td {
    padding: 0.5em;
}

.searchBox {
    display: block;
    padding: 0.5rem;
    padding-right: 2rem;
    font-size: 15pt;
    flex: 1;
    background: white;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.search-type-selector {
    background-color: transparent;
    cursor: pointer;
    border-style: none;
    display: block;
    font-size: 15px;
    margin-bottom: 10px;
}

.animated-background {
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #fff linear-gradient(to right, #fff 8%, #ddd 18%, #fff 33%);
    background-size: 800px 104px;
}

@keyframes placeHolderShimmer {
    0% {
        background-position: -800px 0;
    }
    100% {
        background-position: 800px 0;
    }
}

.big-rect {
    height: 200px;
}

.icon-container {
    line-height: 100%;
}

.relative {
    position: relative;
}

.right-side-absolute {
    position: absolute;
    right: 0.5em;
}

/*.searchBox*/
.react-autosuggest__container {
    position: relative;
}

.column {
    display: flex;
    flex-direction: column;
}

.column-reverse {
    display: flex;
    flex-direction: column-reverse;
}

.row {
    display: flex;
    flex-direction: row;
}

.space-between {
    justify-content: space-between;
}

.gap {
    gap: 1em;
}

.small-gap {
    gap: 0.5em;
}

.tiny-gap {
    gap: 0.25em;
}

.source-icon {
    border-radius: 5px;
    box-shadow: 0 0 0.35em #0002;
    /*border: 1px solid #0002;*/
    margin-top: 5px;
}

.larger-text {
    font-size: 1.1em;
}

.card {
    background-color: white;
    border-radius: 0.5em;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
    padding: 0.5em;
}

.card.clickable {
    cursor: pointer;
}

.card.clickable:hover {
    background-color: #f9f9f9;
}

.align-center {
    align-items: center;
}

.flex1 {
    flex: 1;
}

.clickable {
    cursor: pointer;
}

article {
    background: white;
    padding: 1em;
}

article ul {
    padding-left: 2em;
}

article ul ul {
    font-size: 0.9em;
    padding-left: 1.5em;
    background: #f9f9f9;
    border-radius: 0.5em;
}

article h1:first-child {
    margin-top: 0;
}

article h1,
article h2,
article h3,
article h4 {
    margin-top: 2em;
}

article h1 {
    font-size: 1.5em;
}

article h2 {
    color: #c00;
    border-right: 4px solid #c00;
    background: #fee;
    font-size: 1.25em;
    padding: 0.25em;
}

article h3 {
    font-size: 1.15em;
}

article h4 {
    font-size: 1.1em;
    margin-left: 0.5em;
    color: darkcyan;
    font-weight: normal;
}

a {
    text-decoration: none;
    color: #144e78;
    word-wrap: break-word;
}

ul.simple-list {
    padding-left: 1em;
    list-style-type: none;
}

.muted-text {
    color: #666;
}

.small-text {
    font-size: 0.7em;
    text-transform: uppercase;
    opacity: 0.8;
}

.capitalize-text {
    text-transform: capitalize;
}

.required-star {
    color: red;
    font-size: 1.2em;
}

.toggled-content {
    transition: max-height 0.5s;
    overflow: hidden;
}

.toggled-content.open {
    max-height: 1000px;
}

.toggled-content.closed {
    max-height: 0;
}

input[type='checkbox'],
label {
    cursor: pointer;
}

.no-margin-bottom {
    margin-bottom: 0.5em;
}

.owner-text {
    font-size: 0.8em;
    color: rgba(85, 128, 176);
    font-weight: 600;
}

button.flat {
    background: none;
    border: none;
    padding: 0;
    color: rgba(85, 128, 176);
    cursor: pointer;
    font-size: 1em;
}

.blue-background {
    background-color: rgba(85, 128, 176);
    color: white;
    padding: 0.5em 1em;
    text-align: center;
    cursor: pointer;
}

.theme-felleskatalogen .blue-background {
    background-color: #eff6ff;
    color: #0009;
    box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.1);
}

.theme-hdir .blue-background {
    background-color: rgb(55, 125, 160);
    box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.1);
}

/*
For DEMO & Infobutton:
Mørkeblå: #194E79
Mellomblå: #4782B5
*/
.theme-demo .blue-background {
    background-color: #4782b5;
    box-shadow: 0 0 0.5em rgba(255, 0, 0, 0.1);
}

.theme-infobutton .blue-background {
    background-color: #4782b5;
    box-shadow: 0 0 0.5em rgba(255, 0, 0, 0.1);
}

/* No border, but a mild aura background */
button.flat-button {
    background: #04f1;
    border-radius: 0.5em;
    border: none;
    padding: 0.5em;
    color: rgba(85, 128, 176);
    cursor: pointer;
    font-size: 1em;
}

button.flat-button:hover {
    background: #04f2;
}
button.flat-button:active {
    background: #04f3;
}

.blue-background button,
.blue-background button.flat-button {
    background: #fff3;
    border: 1px solid #fff3;
    color: white;
    cursor: pointer;
}

button.cool {
    background: #f0f0ff;
    padding: 0.5em;
    color: rgba(85, 128, 176);
    border: 1px solid #ddd;
    border-radius: 0.5em;
    cursor: pointer;
    font-size: 1em;
}

.infobutton-schema input[type='text'] {
    padding: 0.5em;
    border: 1px solid #ddd;
    border-radius: 0.5em;
    font-size: 16px;
}

.infobutton-schema select {
    padding: 0.5em;
    border: 1px solid #ddd;
    border-radius: 0.5em;
    font-size: 16px;
    cursor: pointer;
}

.infobutton-schema {
    display: flex;
    flex-direction: column;
    gap: 0.5em;

    background: #f9f9f9;
    padding: 1em;
    border-radius: 0.5em;
    box-shadow: 0 0 0.5em #0003;
}

.infobutton-schema > div {
    display: flex;
    gap: 0.5em;
}

.infobutton-schema > div > * {
    flex: 1;
}

.theme-felleskatalogen .blue-background button {
    background: #0003;
    border: 1px solid #0003;
    color: white;
    cursor: pointer;
}

.theme-felleskatalogen .blue-background button {
    background: rgb(33, 80, 103);
    border: 1px solid rgb(33, 80, 103);
    color: white;
    cursor: pointer;
}

.big-button {
    padding: 0.5em;
    font-size: 1.1em;
}

.chatbox-message {
    background: #f9f9f9;
    border-radius: 0.5em;
    padding: 0.5em;
}

.chatbox-message.assistant {
    /*background: linear-gradient(40deg, #f0feff, #f0e0ff);*/
    /*background: linear-gradient(40deg, #d1e9eb, #f0e0ff);*/
    background: linear-gradient(40deg, #ebdbd163, #f0e0ff);
    margin-right: 0.5em;
}

.chatbox-message.user {
    margin-left: 0.5em;
}

.chatbox-message.in-progress {
    animation: typing-animation 1s infinite;
}

@keyframes typing-animation {
    0% {
        opacity: 0.75;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0.75;
    }
}

.chatbox-input input {
    padding: 0.5em;
    border: 1px solid #ddd;
    border-radius: 0.5em;
    flex: 1;
    font-size: 16px;
}

.chatbox-input button {
    background: #f0f0ff;
    padding: 0.5em;
    color: rgba(85, 128, 176);
    border: 1px solid #ddd;
    border-radius: 0.5em;
    cursor: pointer;
    font-size: 1em;
}

/* Nedtrekksmeny-boks stil */
.react-autosuggest__suggestions-container {
    border: 1px solid #ddd; /* Ramme rundt nedtrekksmenyen */
    background-color: #fff; /* Bakgrunnsfarge */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Skygge for å gi dybde */
    position: absolute; /* Gjør at boksen kan posisjoneres relativt til input-feltet */
    width: 100%; /* Bredden bør være lik søkeboksen */
    z-index: 1000; /* Sørger for at boksen vises over andre elementer */
    max-height: 300px; /* Maks høyde før scrolling */
    overflow-y: auto; /* Aktiverer scrolling */
    top: 100%; /* Posisjon rett under input-feltet */
}

/* Oppdatert stil for hvert forslagsboks */
.react-autosuggest__suggestion-box {
    padding: 10px 20px; /* Øker padding til venstre og høyre for mer avstand */
    border: 1px solid #ddd;
    border-top: none; /* Fjerne top border siden det er overlapp */
    background-color: #f9f9f9;
    cursor: pointer;
    transition: background-color 0.3s; /* Myk overgang for hover-effekt */
}

/* Hover-effekt for hvert element i listen */
.react-autosuggest__suggestion-box:hover {
    background-color: #f0f0f0; /* Endrer bakgrunnsfarge ved hover */
}

/* Stil for valgt element */
.react-autosuggest__suggestions-container .selected {
    background-color: #e9e9e9; /* Bakgrunnsfarge for valgt element */
}

/* Nedtrekksmeny-boks stil */
.react-autosuggest__suggestions-container {
    border: 1px solid #ddd; /* Ramme rundt nedtrekksmenyen */
    background-color: #fff; /* Bakgrunnsfarge */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Skygge for å gi dybde */
    position: absolute; /* Gjør at boksen kan posisjoneres relativt til input-feltet */
    width: 100%; /* Bredden bør være lik søkeboksen */
    z-index: 1000; /* Sørger for at boksen vises over andre elementer */
    max-height: 300px; /* Maks høyde før scrolling */
    overflow-y: auto; /* Aktiverer scrolling */
    top: 100%; /* Posisjon rett under input-feltet */
}

/* Oppdatert stil for hvert forslagsboks */
.react-autosuggest__suggestion-box {
    padding: 10px 20px; /* Øker padding til venstre og høyre for mer avstand */
    border: 1px solid #ddd;
    border-top: none; /* Fjerne top border siden det er overlapp */
    background-color: #f9f9f9;
    cursor: pointer;
    transition: background-color 0.3s; /* Myk overgang for hover-effekt */
}

/* Hover-effekt for hvert element i listen */
.react-autosuggest__suggestion-box:hover {
    background-color: #f0f0f0; /* Endrer bakgrunnsfarge ved hover */
}

/* Stil for valgt element */
.react-autosuggest__suggestions-container .selected {
    background-color: #e9e9e9; /* Bakgrunnsfarge for valgt element */
}

.break-word {
    word-wrap: break-word;
}

.table-of-contents {
    padding: 1em;
    background: rgba(85, 128, 176, 0.1);
    border-radius: 0.5em;
}

.table-of-contents ol {
    padding-left: 1.5em;
    margin: 0;
}

code {
    background: #f9f9f9;
    border-radius: 0.5em;
    padding: 0.25em;
    word-break: break-all;
}

.tag {
    color: #555;
    border-radius: 0.25em;
    font-size: 0.8em;
}



/* Styles for Chatbox and Search Results */


.search-results {
  max-width: 800px;
  margin: 1.5rem auto;
  padding: 1.5rem;
  background-color: #ffffff;
  border-radius: 6px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
}

.search-results h2 {
  font-size: 1.6rem;
  color: #333;
  text-align: center;
  margin-bottom: 1rem;
  font-family: Montserrat-Bold, sans-serif;
  line-height: 1.2;
}

/* KIChat-styling */
.ki-chat {
  /*background-color: #f7f9fc; !* Lysere bakgrunn *!*/
  /*padding: 1rem;*/
  border-radius: 4px;
  font-size: 1rem;
  color: #333;
  line-height: 1.4;
  box-shadow: none; /* Fjern ekstra shadow */
}

.ki-chat p {
    margin-left: -0.4rem;
}


.ki-chat .chat-history {
  overflow-y: auto;
  /*padding-right: 1rem;*/
}

.ki-chat .bot-message,
.ki-chat .user-message {
  padding: 1rem;
  border-radius: 4px;
  font-size: 1rem;
}

.ki-chat .user-message {
    margin-left: 3rem;
    
}
.ki-chat .bot-message {
    /*margin-right: 3rem;*/
}

.ki-chat .bot-message {
  background-color: white;
  color: #333;
}


/* Tving H2 innenfor bot-message til venstrejustering */
.ki-chat .bot-message h2 {
    margin-left: 0rem;
    margin-top: 2rem;
    border-bottom: 1px solid #0001;
}

/* Fjern spesifikt innrykk for den første H2 som vises som en overskrift */
.ki-chat .bot-message > h2:first-of-type {
    margin-left: 0 !important;
    margin-top: 0;
}


/* H2 uten innrykk hvis det er første direkte barn */
.ki-chat .bot-message > h2:first-child {
    margin-left: 0 !important;
    margin-top: 0;
}


.ki-chat .user-message {
    background: #ccc;
  /*color: #fff;*/
    }

/* Minimal loader style */
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.loader-dot {
  width: 6px;
  height: 6px;
  background-color: #007bff;
  border-radius: 50%;
  margin: 0 2px;
  animation: loader-blink 1.4s infinite both;
}


.loader-dot:nth-child(1) {
  animation-delay: 0s;
}
.loader-dot:nth-child(2) {
  animation-delay: 0.2s;
}
.loader-dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes loader-blink {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}




.chat-input-container {
  display: flex;
  margin-top: 1rem;
}

.chat-textarea {
  flex-grow: 1;
  padding: 0.5rem;
  font-size: 1rem;
  font-family: Montserrat-Regular, sans-serif;
  resize: none;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.send-button {
  margin-left: 0.5rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.send-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}





/* index.css */



.var-procedures-button {
  background: none;
  border: none;
  color: #3f51b5;

  cursor: pointer;
  font-size: 0.9rem;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  padding: 0;
  margin-left: 20px;
}

.var-procedures-button:hover {
    text-decoration: underline;
}


.expandable-list {
    margin-left: 20px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
  }


.expandable-list.open {
  max-height: 500px; /* Juster etter behov */
}

.procedure-card {
  background-color: #f8f9fa;
  border-radius: 5px;
  padding: 10px;
  margin: 5px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  gap: 10px;
}

.procedure-card:hover {
  background-color: #e9ecef;
}

.procedure-card a {
  text-decoration: none;
  color: #007bff;
  flex-grow: 1;
}


/* Kopiknapper */
.copy-buttons {
  margin-top: 0.5em;
}


.flat-button:hover {
  text-decoration: underline;
}




 /*

.var-prosedyre-link {
    background: none;
    border: none;
    color: #3f51b5;
  
    cursor: pointer;
    font-size: 0.9rem;
    display: inline-flex;
    align-items: center;
    gap: 4px;
    padding: 0;
  }
  .var-prosedyre-iframe {
    width: 100%;
    height: 400px;
    border: 1px solid #ccc;
    margin-top: 0.5em;
  }
  
  .var-prosedyre-link:hover {
      text-decoration: underline;
  }
  
  .var-prosedyre-iframe {
    border: 1px solid #ccc;
    margin-top: 0.5em;
    width: 100%;
    height: 400px;
  }
  
  
  
  Kopiknapper 
  .copy-buttons {
    margin-top: 0.5em;
  }
  
  
  .flat-button:hover {
    text-decoration: underline;
  }
  */